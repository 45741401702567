import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CloudinaryMediaLibraryComponent } from 'src/app/cloudinary-media-library/cloudinary-media-library.component';
import { UploadModel } from 'src/app/_models';

@Component({
    selector: 'app-cloudinary-input',
    templateUrl: './cloudinary-input.component.html',
    styleUrls: ['./cloudinary-input.component.scss'],
    providers: [CloudinaryMediaLibraryComponent]
})
export class CloudinaryInputComponent {
    @Input() data: {};
    @Input() key_name: string;
    @Input() image_ratio: string = '16:9';
    @Input() label: string;
    @Input() custom_transforms: {} = null;
    @Input() video: boolean = false;
    @Input() disabled: boolean = false;
    @Input() isFormGroup: boolean = false;
    @Input() isFormControl: boolean = false;
    @Output() upload: EventEmitter<UploadModel> = new EventEmitter();

    constructor(
        private mediaLibrary: CloudinaryMediaLibraryComponent,
        private toastr: ToastrService
    ) { }

    get currentFormGroup() {
        return this.data as UntypedFormGroup;
    }

    openMediaLibrary(key: string, ratio: string) {
        this.mediaLibrary.openCloudinary(key, ratio, this.video, this.custom_transforms);
        this.handleUpload();
    }

    handleUpload() {
        this.mediaLibrary.upload.subscribe({
            next: (res: UploadModel) => {
                this.upload.emit(res);
                if (this.isFormGroup) {
                    const group = this.currentFormGroup;
                    group.get(this.key_name).setValue(res.url);
                }
                if (this.isFormControl) {
                    const control = this.data as UntypedFormControl;
                    control.setValue(res.url);
                }
                if (res.warning_message) {
                    this.toastr.warning(res.warning_message, null, { disableTimeOut: true, tapToDismiss: true, closeButton: true });
                }
                if (res.key_name === this.key_name) {
                    this.data[this.key_name] = res.url;
                }
            }
        });
    }
}
