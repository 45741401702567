import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountModel, LocalStoreUser } from '../_models';
import { AccountService } from './account.service';
import { generateHeaders } from './headers';

@Injectable({
    providedIn: 'root'
})
export class UserAccountService {
    public account: BehaviorSubject<AccountModel | null> = new BehaviorSubject(null);
    public user: BehaviorSubject<LocalStoreUser>;

    constructor(
        private accountAPI: AccountService
    ) {
        this.user = new BehaviorSubject(generateHeaders.getLocalUserToken());
    }

    getUser() {
        return this.user.value?.meta?.current_user ? this.user.value.meta.current_user : null;
    }

    getUserRole() {
        return this.user.value?.meta?.current_user ? this.user.value.meta.current_user.role : null;
    }


    async initUsersAccount(account_id?: string): Promise<any> {

        const user_value = this.user.getValue();
        const account_value = this.account.getValue();

        // We already have the account from the guard init
        if (account_value && account_value.id === account_id) {
            return;
        }

        // for super admins calls that will include an account id
        if (account_id) {
            const account = await this.getAccountDetails(account_id)
            this.account.next(account);
            return;
        }

        // account admins will have an account id attached to their auth payload
        if (user_value && user_value.meta.current_user.account_id) {
            const account = await this.getAccountDetails(user_value.meta.current_user.account_id)
            this.account.next(account);
            return;
        }
    }

    async setUser() {
        this.user.next(generateHeaders.getLocalUserToken());
    }

    private async getAccountDetails(account_id: string) {
        return await this.accountAPI.getAccount(account_id);
    }
}
