import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { CloudinaryMediaLibraryModule } from './cloudinary-media-library/cloudinary-media-library.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { MaterialModule } from './app.material.module';
import { ConfirmChromeDialogComponent } from './confirm-chrome-dialog/confirm-chrome-dialog.component';


@NgModule({
    declarations: [
        AppComponent,
        ConfirmChromeDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        CloudinaryMediaLibraryModule,
        MaterialModule
    ],
    providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
    bootstrap: [AppComponent]
})
export class AppModule { }
