import { HttpHeaders } from '@angular/common/http';
import { LocalStoreUser } from '../_models';

export class Headers {
    private known_hosts = {
        localhost: 'modLocal',
        dev: 'modDev',
        prod: 'modProd'
    };

    public getLocalUserToken(): LocalStoreUser {
        for (const host of Object.keys(this.known_hosts)) {
            if (location.host.includes(host)) {
                const local_key = localStorage.getItem(this.known_hosts[host]);
                return JSON.parse(local_key);
            } 
        }
        if (location.host.includes('appcats')) {
            const local_key = localStorage.getItem(this.known_hosts.prod);
            return JSON.parse(local_key);
        }
        return null;
    }

    buildIdentifierHeader(account_identifier: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Bleachr': `${account_identifier}`
            })
        };
        return httpOptions;
    }

    buildWebAPIHeader() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.getLocalUserToken().token}`,
                'team_limited': `${this.getLocalUserToken().meta.current_user.limit_to_team_based_access}`
            })
        };
        return httpOptions;
    }

    buildFullHeader(account_identifier: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'X-Bleachr': `${account_identifier}`,
                Authorization: `Bearer ${this.getLocalUserToken().token}`,
            })
        };
        return httpOptions;
    }
}


export const generateHeaders = new Headers();