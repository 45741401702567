import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { CloudinaryMediaLibraryComponent } from './cloudinary-media-library.component';
import { CloudinaryInputComponent } from './cloudinary-input/cloudinary-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    declarations: [CloudinaryMediaLibraryComponent, CloudinaryInputComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        ReactiveFormsModule
    ],
    exports: [CloudinaryInputComponent, CloudinaryMediaLibraryComponent]
})
export class CloudinaryMediaLibraryModule { }
