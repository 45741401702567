import { Component, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CloudinaryInitConfig, CloundinaryUploadResponse, UploadModel } from '../_models';
import { UserAccountService } from '../_services/user-account.service';
declare var cloudinary: any;
import sha256 from 'crypto-js/sha256';

@Component({
    selector: 'app-cloudinary-media-library',
    templateUrl: './cloudinary-media-library.component.html',
    styleUrls: ['./cloudinary-media-library.component.scss']
})
export class CloudinaryMediaLibraryComponent {
    @Output() upload: EventEmitter<UploadModel> = new EventEmitter<UploadModel>();
    public payload: UploadModel;
    public many_enabled: boolean;
    public key_name: string | number = null;
    public keys_array: string[] = [];
    public aspect_ratio_map = {
        '39:18': '39:18',
        '18:39': '18:39',
        'SponsorBanner': '9:1',
        'SixteenByTen': '16:10',
        'SixteenByNine': '16:9',
        'Square': '4:4',
        'FourByThree': '4:3',
        'OneByOne': '1:1',
        'ThreeByOne': '3:1',
        'FourByOne': '4:1',
        'FiveByOne': '5:1',
        'FiveByThree': '5:3',
        'SevenByThree': '7:3',
        'SixByOne': '6:1',
        'SevenByOne': '7:1',
        'EightByOne': '8:1',
        'NineByOne': '9:1',
        'TenByOne': '10:1'
    };
    private config: CloudinaryInitConfig;

    constructor(private userService: UserAccountService) { }

    async openCloudinary(
        key: string | number,
        ratio: string,
        video?: boolean,
        custom_transforms?: { [key: string]: any },
        many = false,
        additional_transforms?: { [key: string]: any }
    ) {
        this.config = this.cloudinaryConfig();

        if (Array.isArray(key)) {
            this.keys_array = key;
        }
        this.key_name = key;
        this.many_enabled = many;
        if (video) {
            this.cloudinaryVideoInit(custom_transforms);
        } else {
            await this.cloudinaryInit(this.handleRatio(ratio), custom_transforms, additional_transforms);
        }
        const cloud_container = document.getElementById('cloudinary');
        cloud_container.classList.toggle('active');
        window['ml'].show({
            folder: { path: this.config.default_folder }
        });

    }

    handleRatio(ratio: string): string {
        if (ratio) {
            return ratio.includes(':') ? ratio : this.aspect_ratio_map[ratio];
        }
        return null;
    }

    closeCloudinary() {
        const cloud_container = document.getElementById('cloudinary');
        const cloud_iframe = document.getElementsByTagName('iframe')[0];
        cloud_container.classList.toggle('active');
        cloud_iframe.remove();
    }

    cloudinaryVideoInit(custom_transforms: { [key: string]: any }) {
        const default_transforms = {};
        const transforms = custom_transforms || default_transforms;
        window['ml'] = cloudinary.createMediaLibrary({
            cloud_name: 'bleachr',
            api_key: '675539299559564',
            inline_container: '.cloudinary-container',
            default_transformations: [transforms]
        }, {
            insertHandler: (data) => {
                this.handleInsert(data);
                this.closeCloudinary();
            }
        }
        );
    }

    async cloudinaryInit(ratio: string = '16:9', custom_transforms?: {} | [], additional_transforms?: {}) {
        const default_transforms = { quality: 'auto', width: 1344, aspect_ratio: ratio, gravity: 'faces:auto', crop: 'fill' };
        const transforms = custom_transforms || default_transforms;
        const transforms_arr = Array.isArray(custom_transforms) ? [...custom_transforms] : [transforms];
        if (additional_transforms) {
            transforms_arr.push(additional_transforms);
        }
        const now = new Date().getTime();

        const signature = sha256(`cloud_name=bleachr&timestamp=${now}&username=${this.config.username}${this.config.api_key}`);

        window['ml'] = cloudinary.createMediaLibrary({
            cloud_name: 'bleachr',
            api_key: '675539299559564',
            username: this.config.username,
            timestamp: now.toString(),
            signature: signature.toString(),
            inline_container: '.cloudinary-container',
            default_transformations: [transforms_arr]
        }, {
            insertHandler: (data: CloundinaryUploadResponse) => {
                if (this.many_enabled) {
                    this.handleMultipleInsert(data);
                } else {
                    this.handleInsert(data);
                }
                this.closeCloudinary();
            }
        }
        );
    }

    handleInsert(data: CloundinaryUploadResponse) {
        const image_url = this.handleFormat(data.assets[0]);
        const public_id_value = data.assets[0]['public_id'];
        const fileSize = data.assets[0]['bytes'];
        const fileFormat = data.assets[0]['format'];
        this.payload = {
            url: image_url,
            public_id: public_id_value,
            key_name: this.key_name,
            file_size: fileSize,
            file_format: fileFormat,
            key_name_array: this.keys_array,
            warning_message: this.handleWarning(fileFormat, fileSize)
        };
        this.upload.emit(this.payload);
    }

    handleWarning(format: string, size: number) {
        const video_formats = ['mp4', 'avi', 'mkv'];
        const image_formats = ['jpg', 'png', 'gif'];

        if (video_formats.includes(format)) {
            if (this.checkFileSize(size) > 25 && this.checkFileSize(size) < 50) {
                return 'Warning — this file is rather large and will be very slow to load';
            } else if (this.checkFileSize(size) > 50 && this.checkFileSize(size) < 75) {
                return 'Warning — this file is very large and will be slow to load or will not load at all';
            } else if (this.checkFileSize(size) > 75) {
                return 'Warning — this file is EXTREMELY LARGE and will unlikely load on the client, please upload a smaller video';
            } else {
                return null;
            }
        } else if (image_formats.includes(format)) {
            if (this.checkFileSize(size) > 5 && this.checkFileSize(size) < 15) {
                return 'Warning — this file is rather large and will be very slow to load';
            } else if (this.checkFileSize(size) > 15 && this.checkFileSize(size) < 25) {
                return 'Warning — this file is very large and will be slow to load or will not load at all';
            } else if (this.checkFileSize(size) > 25) {
                return 'Warning — this file is EXTREMELY LARGE and will unlikely load on the client, please upload a smaller image';
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    checkFileSize(value: number): number {
        // Note this value is in bytes
        return Math.floor(value / 1000000);
    }

    handleMultipleInsert(data) {
        const assets = data.assets;
        const imgs = assets.map(ass => {
            return this.playLoadFactory(ass);
        });
        this.upload.emit({
            url: null,
            key_name: null,
            file_size: null,
            public_id: null,
            file_format: null,
            imgs
        });
    }

    playLoadFactory(a) {
        const url = this.handleFormat(a);
        const public_id = a.public_id;
        return { url, public_id, key_name: this.key_name };
    }

    handleFormat(asset): string {
        switch (asset.format) {
            case 'gif':
                return asset['derived'][0]['secure_url'].replace(',w_2688', '');
            case 'unknown':
                // default to png if unknown
                return asset['derived'][0]['secure_url'].replace('.unknown', '.png');
            default:
                return asset['derived'][0]['secure_url'];
        }
    }

    cloudinaryConfig() {
        const user = this.userService.user.getValue();
        switch (user.meta.current_user.role) {
            case 'super_admin':
                return {
                    username: environment.cloudinary_config.users.super_admin,
                    api_key: environment.cloudinary_config.api_key
                }

            case 'account_admin':
                return {
                    username: environment.cloudinary_config.users.account_admin,
                    api_key: environment.cloudinary_config.api_key,
                    default_folder: environment.cloudinary_config.default_folder.account_admin
                }
            default:
                return null;
        }
    }
}
