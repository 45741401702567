<mat-form-field style="width: 100%" appearance="outline" *ngIf="!isFormGroup">
    <mat-label>{{ label }} URL</mat-label>
    <input matInput [disabled]="disabled" [(ngModel)]="data[key_name]" appTrim autocomplete="false" />

    <button [disabled]="disabled" mat-button matSuffix mat-icon-button (click)="openMediaLibrary(key_name, image_ratio)">
        <mat-icon>add_photo_alternate</mat-icon>
    </button>
    <button [disabled]="disabled" mat-button matSuffix mat-icon-button (click)="data[key_name] = ''">
        <mat-icon>clear</mat-icon>
    </button>
</mat-form-field>

<div [formGroup]="currentFormGroup" *ngIf="isFormGroup">
    <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>{{ label }} URL</mat-label>
        <input matInput appTrim autocomplete="false" [formControlName]="key_name" />

        <button [disabled]="disabled" mat-button matSuffix mat-icon-button (click)="openMediaLibrary(key_name, image_ratio)">
            <mat-icon>add_photo_alternate</mat-icon>
        </button>
        <button [disabled]="disabled" mat-button matSuffix mat-icon-button (click)="data[key_name] = ''">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-form-field>
</div>
