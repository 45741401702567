import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccountModel } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(
        private http: HttpClient
    ) { }

    async getAllAccounts(): Promise<AccountModel[]> {
        return await this.http.get<{ data: AccountModel[], error: string }>(`${environment.webAPI}/accounts/all?keys=id,name`)
            .toPromise()
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                return [];
            })
    }

    async getAccount(account_id: string): Promise<AccountModel> {
        return await this.http.get<{ data: AccountModel, error: string }>(`${environment.webAPI}/accounts/${account_id}`)
            .toPromise()
            .then(res => {
                return res.data;
            })
            .catch(error => {
                console.error(error);
                return null;
            });
    }
}
