import { Component, OnInit } from '@angular/core';
import { ConfirmChromeDialogComponent } from './confirm-chrome-dialog/confirm-chrome-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public browser_checked: boolean = false;

    constructor( private dialog: MatDialog, private router: Router ) {
        
    }
    
    ngOnInit() {
        this.router.events.subscribe(route => {
            if(route instanceof NavigationEnd && !this.browser_checked){
                const is_login = route.url.includes("login") || route.urlAfterRedirects.includes("login")
                if(navigator.userAgent.indexOf("Chrome") < 0 && !route.url.includes("login")){
                    this.dialog.open(ConfirmChromeDialogComponent, { });
                    this.browser_checked = true;
                }
            }
        });  
    }
}
