import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStoreUser, BleachrRoles } from '../_models';
import { UserAccountService } from './../_services/user-account.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    private known_hosts = {
        localhost: 'modLocal',
        dev: 'modDev',
        prod: 'modProd'
    }

    constructor(
        private router: Router,
        private userService: UserAccountService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let token: LocalStoreUser = ['modProd', 'modDev', 'modLocal'].reduce((acc, key) => {
            if (localStorage.getItem(key)) {
                acc = JSON.parse(localStorage.getItem(key));
            }
            return acc
        }, {});

        const tokenInRoute = this.getTokenInRoute(route, token);
        if (tokenInRoute) {
            token = tokenInRoute;
        }

        if (!token.token) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        if (new Date().getTime() >= Number(token.ttl)) {
            this.router.navigate(['/login']);
        }


        return new Date().getTime() <= Number(token.ttl) ? true : false;
    }

    private convertToBool(route_param: string): boolean {
        if (route_param === 'false') {
            return false;
        }
        if (route_param === 'true') {
            return true;
        }
        return null;
    }

    private nullify(route_param: string) {
        if (route_param.trim() === 'null' || route_param.trim() === 'undefined') {
            return null;
        }
        return route_param.trim();
    }

    private envKeyName(): string {
        for (const host of Object.keys(this.known_hosts)) {
            if (location.host.includes(host)) {
                return this.known_hosts[host];
            }
        }
        if (location.host.includes('appcats')) {
            return this.known_hosts.prod;
        }
        return '';
    }

    private getTokenInRoute(route: ActivatedRouteSnapshot, token: LocalStoreUser) {
        if (route && route.params && route.params.token && token && !token.token) {
            const key_name = this.envKeyName();
            const { params: { ttl, id, email, first_name, last_name, role, account_id, limit_to_team_based_access, can_approve_payouts, can_edit_users } } = route;
            const user_object = {
                token: this.nullify(route.params.token),
                ttl: +ttl,
                meta: {
                    current_user: {
                        id: this.nullify(id),
                        email: this.nullify(email),
                        first_name: this.nullify(first_name),
                        last_name: this.nullify(last_name),
                        account_id: this.nullify(account_id),
                        role: this.nullify(role) as BleachrRoles,
                        limit_to_team_based_access: this.convertToBool(this.nullify(limit_to_team_based_access)),
                        can_approve_payouts: this.convertToBool(this.nullify(can_approve_payouts)),
                        can_edit_users: this.convertToBool(this.nullify(can_edit_users))
                    }
                }
            }
            localStorage.setItem(key_name, JSON.stringify(user_object));
            this.userService.setUser();
            return user_object;
        }
        return null;
    }

}
