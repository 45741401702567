import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { BroadcastTokenGuard } from './_guards/broadcast-token.guard';

const routes: Routes = [
    { path: '', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard] },
    { path: ':token/:ttl/:account_id/:can_approve_payouts/:can_edit_users/:email/:first_name/:last_name/:limit_to_team_based_access/:role/:id', loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule), canActivate: [AuthGuard] },
    { path: 'mod-content', loadChildren: () => import('./shared/mod-content/mod-content.module').then(m => m.ModContentModule) },
    { path: 'accounts', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule), canActivate: [AuthGuard] },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'team', loadChildren: () => import('./team-access/team-access.module').then(m => m.TeamAccessModule), canActivate: [AuthGuard] },
    { path: 'profile-media', loadChildren: () => import('./profile-media/profile-media.module').then(m => m.ProfileMediaModule) },
    { path: 'banned-users', loadChildren: () => import('./banned-users/banned-users.module').then(m => m.BannedUsersModule), canActivate: [AuthGuard] },
    { path: ':broadcast_id', loadChildren: () => import('./broadcast-access/broadcast-access.module').then(m => m.BroadcastAccessModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
