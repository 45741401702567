// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    apiHeader: 'tennis-one',
    production: false,
    elixirAPI: 'https://fan-engine-dev.herokuapp.com',
    phoenixSocket: 'wss://fan-engine-dev.herokuapp.com',
    webAPI: 'https://bleachr-web-api-dev.herokuapp.com/api',
    gamingAPI: 'https://bleachr-gaming-dev.herokuapp.com',
    cloudinary_config: {
        api_key: 'A3IDmKylIQFeqSi7ThhsmAsHB6s',
        users: {
            super_admin: 'admin2@bleachr.co',
            account_admin: 'support@bleachr.co'
        },
        default_folder: {
            account_admin: 'client_admin/cvl_uploads'
        }
    },
    streamio_api_key: 'puecw2hftwc7',
    GOOGLE_API_KEY: 'AIzaSyBBPOaamYiSWUytU5botyodxyXktsi2hRw',
    youtubeAPI: 'https://youtube.googleapis.com/youtube/v3',
    chatAdmin: 'https://bleachr-chat-dev.herokuapp.com',
    WebExClientID: 'C290bf9b88e67eb06d05cd86da3aa53c8e498cbb705aa5463b72e7cfff87c719d'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
